import React from "react"
import { Container, Row, Col } from "react-grid-system"

type PlaygroundProps = {}

function PlaygroundContent() {
  return (
    <div>
      <div className="background" style={{ padding: 20 }}>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <p>content</p>
        <p className="text--primary">Primary content</p>
        <p>
          <a href="#">Link</a>
        </p>
        <p>
          <button className="button">Button</button>
          <button className="button" disabled>
            Disabled Button
          </button>
          <a href="#" className="button">
            Linked Button
          </a>
        </p>
      </div>
    </div>
  )
}
function Playground(props: PlaygroundProps): React.ReactElement {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={6}>
          <div className="theme-dark">
            <PlaygroundContent />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="theme-light">
            <PlaygroundContent />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Playground
